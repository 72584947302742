import { Typography, Paper, Grid, Link } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material'; // Import necessary hooks
import Auth from "../components/Auth.js";

const Landing = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // Check if the screen width is >= 900px (md breakpoint)

  return (
    <Grid container spacing={4} style={{ height: '100%'}}>
      {/* Left Side - GIF and Project Description */}
      <Grid item xs={12} md={isDesktop ? 7 : 12} style={{ display: 'flex', 
        flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <Paper elevation={0} style={{ 
          padding: '20px 30px', 
          textAlign: 'center', 
          background: 'transparent',
        }}>
          <img
            src="stockticker.gif" // Replace with your GIF URL
            alt="Physical Stock Ticker"
            style={{ width: '90%', maxHeight: '400px', objectFit: 'cover', borderRadius: '16px', marginBottom: '20px' }}
          />
          <Typography variant="h4" style={{ fontWeight: 700, color: '#222', marginBottom: '20px' }}>
            Stock Ticker Project
          </Typography>
          <Typography variant="body1" style={{ color: '#444', fontSize: '16px', lineHeight: '1.8', marginBottom: '20px' }}>
            Track real-time stock market data using a physical device connected to your network. Leverage Finnhub API to fetch stock prices and display them on a customizable ticker.
          </Typography>
          <Typography variant="body1" style={{ color: '#444', fontSize: '16px', lineHeight: '1.8', marginBottom: '20px' }}>
            Choose the stocks, ETFs, cryptocurrencies, and customize the display settings to match your preferences. Stay updated with live market data displayed right at your desk.
          </Typography>
          <Link href="https://github.com/yahnyshc/stockTicker" target="_blank" rel="noopener" underline="hover" style={{ fontSize: '16px', fontWeight: 600, color: '#1e88e5' }}>
            View Project on GitHub
          </Link>
        </Paper>
      </Grid>

      {/* Right Side - Login/Signup Toggle */}
      {isDesktop && (
        <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', padding: '20px' }}>
          <Paper 
            elevation={0} 
            style={{ 
              padding: '7%', 
              borderRadius: '20px', 
              backgroundColor: '#ffffff',
              boxShadow: '0 8px 30px rgba(0,0,0,0.1)', // Add stronger shadow for clearer separation
            }}>
            <Auth />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default Landing;
